/* Sections.css */
:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --background-color: #f4f6f9;
  --text-color: #34495e;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
}

.sections-content {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.section {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  padding: 20px;
}

.section h2 {
  color: var(--secondary-color);
}

.video-container {
   position: relative;
   padding-bottom: 56.25%; /* Aspect ratio */
   height: 0;
   overflow: hidden;
   width: 100%;
}

.video-container iframe,
.video-container object,
.video-container embed {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

/* Media Queries for responsiveness */
@media (max-width:768px) {
   .sections-content {
       padding-left:10px;
       padding-right:10px;
   }

   .section {
       padding-left:15px;
       padding-right:15px;
   }
}
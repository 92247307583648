/* PurchasePage.css */
.purchase-page {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .checkout-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-row {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
  }
  
  .error-message {
    color: #dc3545;
    margin-bottom: 10px;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .additional-info {
    margin-top: 20px;
    padding: 15px;
    background-color: #e9ecef;
    border-radius: 4px;
  }
  
  .additional-info p {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .additional-info ul {
    padding-left: 20px;
  }
  
  .success-message {
    text-align: center;
    color: #28a745;
  }
  
  .success-message h3 {
    margin-bottom: 10px;
  }

  .purchase-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .purchase-page h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .custom-message {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .custom-message p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
  }
  
  .custom-message a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .custom-message a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  .additional-info {
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .additional-info p {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .additional-info ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .additional-info li {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
  }
/* ---- (Optional) Google Fonts Import ---- */
/* You can also place this in index.html or another global stylesheet */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600&display=swap');

/* Global Resets & Body Styling */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to right, #f8fbfd, #edf2f7);
  color: #2c3e50;
}

/* Container for the Free Content Page */
.free-content-container {
  max-width: 1200px;
  margin: 0 auto;
  /* Add horizontal padding here */
  padding: 20px 40px; 
}


/* Main Title */
.free-content-container h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 40px;
  color: #2c3e50;
}

/* Lesson Grid (responsive) */
.lesson-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

/* Lesson Card */
.lesson-card {
  max-width: 600px; 
  margin: 20px auto; /* 20px top/bottom, auto left/right */
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.06);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}



.lesson-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 30px rgba(0,0,0,0.12);
}

/* Card Media (Image) */
.card-media {
  text-align: center; 
  padding: 20px;    
  /* width: 100%; */
  /* Remove height: 600px; */
  overflow: hidden; /* optional: keeps images from pushing beyond container */
}

.card-media img {
  display: inline-block;
  max-width: 70%;  /* or 60%, 80%, whatever suits you */
  height: auto;
}


/* Card Content */
.card-content {
  padding: 20px;
}

.card-content h2 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #34495e;
}

.description {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #7f8c8d;
  margin-bottom: 20px;
}

/* Lesson Details */
.lesson-details {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.duration, 
.difficulty {
  font-size: 0.85rem;
  background: #ecf0f1;
  color: #7f8c8d;
  padding: 5px 10px;
  border-radius: 999px;
}

/* Tags */
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.tag {
  font-size: 0.75rem;
  background: #ecf0f1;
  color: #7f8c8d;
  padding: 5px 10px;
  border-radius: 999px;
}

/* Start Button */
.start-button {
  display: inline-block;
  background: #3498db;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.start-button:hover {
  background: #2980b9;
}

/* Footer */
.footer {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  font-size: 0.9rem;
  color: #7f8c8d;
  border-top: 1px solid #ecf0f1;
}

.intro-text {
  max-width: 800px;
  margin: 0 auto 30px auto; /* center horizontally, add spacing below */
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-align: center;       /* center the text if you prefer */
}

/* Auth Section */
.auth-section {
  text-align: center;
  margin: 20px 0;
}

.auth-button {
  font-size: 18px;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  width: 80%;
  max-width: 300px;
  text-align: center;
}

.sign-in {
  background-color: #4285F4; /* Google Blue */
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.sign-in:hover {
  background-color: #357ae8;
}

.sign-out {
  background-color: #d9534f; /* Red */
  color: white;
}

.sign-out:hover {
  background-color: #c9302c;
}

.container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #2c3e50;
}

.section {
    margin-bottom: 30px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #34495e;
}

.explanation {
    font-size: 0.9rem;
    color: #7f8c8d;
    margin-bottom: 15px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label {
    font-size: 0.9rem;
    color: #34495e;
    text-align: left;
}

.input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.button {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.button:hover {
    background: #2980b9;
}

.googleButton {
    padding: 10px;
    background: #db4437;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.googleButton:hover {
    background: #c1351d;
}

.error {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.success {
    color: #2ecc71;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
    color: #7f8c8d;
    font-size: 0.9rem;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
}

.separator::before {
    margin-right: 10px;
}

.separator::after {
    margin-left: 10px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

.ctaButton {
  background-color: #ffd700; /* Gold */
  color: #000; /* Black text for contrast */
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  display: inline-block;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.ctaButton:hover {
  background-color: #ffcc00; /* Slightly darker gold */
  transform: scale(1.05); /* Subtle enlargement */
  text-decoration: underline;
}


.header {
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
  color: #fff;
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.header h1 {
  font-size: 2.8em;
  margin-bottom: 15px;
  font-weight: bold;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}



.header p strong {
  color: #ffd700; /* Use gold for emphasis */
  font-weight: bold;
}

.header p {
  font-size: 1.3em;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  color: #ffffff; /* Change to a lighter white */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Soft shadow */
}


.userSection {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 40px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.userSection p {
  font-size: 1.1em;
  color: #343a40;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardContent {
  padding: 20px;
}

.cardTitle {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #007bff;
}

.cardDescription {
  font-size: 0.95em;
  color: #6c757d;
  margin-bottom: 15px;
}

.cardTags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.tag {
  background-color: #e9ecef;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 0.85em;
  color: #343a40;
}

.cardLink, .navButton {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1em;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.cardLink:hover, .navButton:hover {
  background-color: #0056b3;
}

.lockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2em;
  border-radius: 8px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2em;
  color: #343a40;
}

.progressBar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
}

.progressFill {
  height: 100%;
  background-color: #28a745;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.signOutButton {
  background-color: #dc3545;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.signOutButton:hover {
  background-color: #bd2130;
}

/* Additional Styles for Dialog */
.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.dialogContent {
  position: relative;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.closeButton {
  position: fixed;
  top: 20px;
  right: 20px;
  color: white;
  background-color: #e74c3c;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  z-index: 1000;
}

.closeButton:hover {
  background-color: #c0392b;
  transform: scale(1.1);
}

.closeButton:active {
  background-color: #a93226;
  transform: scale(1);
}

.closeButton:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(255, 87, 34, 0.25);
}

.free-content-section {
  margin: 20px 0;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.freeContent {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.freeContent h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #007bff;
}

.freeContent p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
}

.calculusLevels {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  gap: 30px;
}

.levelButton {
  text-align: center;
  padding: 25px;
  border-radius: 10px;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 30%;
}

.levelButton:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.2);
}

.levelButton h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #007bff;
  font-weight: bold;
}

.levelButton p {
  font-size: 1em;
  color: #555;
  margin-bottom: 20px;
  font-weight: bold;
}

.levelButton .cardLink {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border-radius: 6px;
  text-decoration: none;
  font-size: 1em;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.levelButton .cardLink:hover {
  background-color: #0056b3;
}

.developedBy {
  margin: 40px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.developedBy h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #007bff;
}

.developerInfo {
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.developerInfo p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #333;
  margin-bottom: 15px;
}

.developerInfo a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.developerInfo a:hover {
  color: #0056b3;
  text-decoration: underline;
}
/* VerifyEmail.css */
.verify-email-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .verify-email-title {
    color: #333;
    margin-bottom: 20px;
  }
  
  .verify-email-message {
    color: #666;
    margin-bottom: 15px;
  }
  
  .verify-email-loading {
    color: #007bff;
  }
  
  .verify-email-error {
    color: #dc3545;
  }
  
  .verify-email-success {
    color: #28a745;
  }
/* ConversationBlock.css */
.conversation-block {
  display: flex;
  align-items: flex-start;
  margin: 30px auto;
  padding: 25px;
  border-radius: 12px;
  background-color: var(--section-color-1);
  max-width: 700px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.08);
  transition: box-shadow 0.3s ease;
}

.conversation-block:hover {
  box-shadow: 0 8px 16px rgba(0,0,0,0.12);
}

.conversation-block-left {
  flex-direction: row;
}

.conversation-block-right {
  flex-direction: row-reverse;
}

.conversation-block-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--primary-color);
}

.conversation-block-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.conversation-block-title {
  margin: 0 0 15px 0;
  font-size: 1.3rem;
  color: var(--secondary-color);
  text-align: center;
  font-weight: 600;
}

.conversation-block-text {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-color);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
/* styles.css */
.toggle-content-container {
    margin: 10px 0;
  }
  
  .toggle-button {
    background-color: #007BFF; /* Bootstrap blue color */
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button:hover {
    background-color: #0056b3; /* Darker shade of blue */
  }
  
  .toggle-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Blue outline when focused */
  }
  
  .toggle-content {
    margin-top: 15px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
  }
  
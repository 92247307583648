.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2em;
    color: #007bff;
  }
  
  h2 {
    color: #007bff;
    font-size: 1.6em;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 1.2em;
    color: #333;
    margin-top: 20px;
  }
  
  p {
    font-size: 1.1em;
    color: #444;
    line-height: 1.6;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    font-size: 1.1em;
    margin-bottom: 8px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #ddd;
    margin: 20px 0;
  }
  
  .highlight {
    font-size: 1.2em;
    font-weight: bold;
    color: #d9534f;
  }
  
  .closing {
    font-size: 1.2em;
    font-weight: bold;
    color: #28a745;
  }
  